import * as React from "react";
import Footer from "../components/footer";
import Layout from "../components/layout";
import styled from "styled-components";
import Block from "../components/block";

const Page = styled.div``;

const NotFoundPage = () => {
  return (
    <Page>
      <Layout title={"Page Not Found"}>
        <Block fullHeight={true}>
          <h1>Page Not Found</h1>
        </Block>
        <Footer />
      </Layout>
    </Page>
  );
};

export default NotFoundPage;
